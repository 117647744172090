<template>
  <div id="cgu">
    <section
      id="list_services"
      class="grid-zone s5"
    >
      <p v-html="$t('legal.liste-services-cgu')" />
      <nav>
        <ul>
          <li
            v-for="service in allServices.filter((service) => service[`nom${country.toUpperCase()}`])"
            :key="service.slug_service"
          >
            <router-link
              :to="{ name: `cgu_${service.slug_service}`, }"
              v-html="service[`nom${country.toUpperCase()}`]"
            />
          </li>
        </ul>
      </nav>
    </section>

    <section
      v-if="$route.meta.title"
      class="grid-zone"
    >
      <h2
        class="s3"
        v-html="$route.meta.title"
      />
      <router-view />
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import config from "@/config";

/**
 * Affiche la page globale des CGU des services.
 */
export default {
  name: "Cgu",
  computed: {
    ...mapGetters(["country"]),
    allServices() {
      return config.allServices;
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_legal.scss";
</style>
